import React from "react";
import I18nContext from "../lib/i18n-context";

const HowItWorks = () => {
  const { locale } = React.useContext(I18nContext);

  const data = locale.howItWorks;

  return (
    <section
      className="w-full text-white bg-darkblue bg-fixed bg-left-top bg-no-repeat"
      style={{ backgroundImage: `url(${"/assets/works-together-bg.svg"})` }}
    >
      <div className="container max-w-screen-xl flex flex-col items-center justify-center gap-6 lg:gap-10 space-y-6 sm:space-y-0 md:flex-row py-16 lg:py-20 px-10 xl:px-12">
        <div>
          <img
            className="w-full"
            src="/assets/work-together.png"
            alt="display design of ideas section"
          />
        </div>
        <div className="md:pt-8 lg:pt-14 xl:pr-16">
          <div className="space-y-5 sm:w-96 md:w-64 xl:w-80">
            <h2 className="text-3xl sm:text-3xl md:text-2xl lg:text-3xl xl:text-4xl font-arima font-light">
              {data.title}
            </h2>
            <p className="font-poppins text-xs sm:text-sm md:text-xs xl:text-sm font-light">
              {data.description}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
