import React from "react";
import I18nContext from "../lib/i18n-context";

const OurServicesBanner = () => {
  const { locale } = React.useContext(I18nContext);

  const data = locale.ourServicesBanner;

  return (
    <section
      className="w-full mt-20 bg-cover bg-fixed text-darkblue "
      style={{
        backgroundImage: `url(${"/assets/our-model-bg.svg"})`,
        backgroundPosition: "calc(100% - 200px) calc(100% - 10px)",
      }}
    >
      <div className="container max-w-screen-xl flex flex-col items-center justify-center sm:flex-row py-12 lg:py-20 px-6 md:px-12 lg:px-20 xl:px-24 space-y-10 sm:space-y-0">
        <div className="flex-1 sm:order-last">
          <img
            className="w-full"
            src="/assets/our-model-display.png"
            alt="display of our services"
          />
        </div>
        <div className="flex-1 w-full space-y-6">
          <div className="text-left space-y-7 font-arima px-4 sm:px-0 sm:pr-6 md:pr-20 lg:pr-28">
            <h2 className="text-3xl sm:text-2xl md:text-3xl lg:text-4xl">
              {data.title}
            </h2>
            <p className="font-poppins">{data.description}</p>
            <div>
              <a href="#main">
                <svg
                  className="h-3"
                  xmlns="http://www.w3.org/2000/svg"
                  width="28.643"
                  height="15.736"
                  viewBox="0 0 28.643 15.736"
                >
                  <path
                    id="Path_701"
                    data-name="Path 701"
                    d="M2301.307,677.7l13.615,13.615,13.615-13.615"
                    transform="translate(-2300.6 -676.996)"
                    fill="none"
                    stroke="#1e3d49"
                    strokeWidth="2"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurServicesBanner;
