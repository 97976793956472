import React, { useEffect } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import NavBar from "../components/nav";
import Footer from "../components/Footer";
import "aos/dist/aos.css";
import AOS from "aos";
import Seo from "../components/Seo";
import OurServicesBanner from "../components/OurServicesBanner";
import StrategyBlock from "../components/StrategyBlock";
import HowItWorks from "../components/HowItWorks";
import OurServicesInAction from "../components/OurServicesInAction";

const OurServices = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <Layout>
      <Seo
        title={"Our services"}
        description={
          "We are on a mission to become the global leader in building outstanding, purposeful and resilient brands in China."
        }
      />
      <header>
        <NavBar
          navClass={"white-navbar"}
          logo={"/assets/main-logo-blue.svg"}
          class={"text-darkblue border-darkblue"}
        />
        <OurServicesBanner />
      </header>
      <main id="main">
        <StrategyBlock />
        <HowItWorks />
        <OurServicesInAction />
        <Footer />
      </main>
    </Layout>
  );
};

export default OurServices;

export const pageQuery = graphql`
  query OurServicesQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;
