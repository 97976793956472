import React from "react";
import Strategy from "../components/our-services-signs";
import I18nContext from "../lib/i18n-context";

const StrategyBlock = () => {
  const { locale } = React.useContext(I18nContext);

  const data = locale.features;

  const strategy = data.strategy;
  const brand = data.brand;
  const sales = data.sales;

  return (
    <section className="w-full pt-8 md:py-6 pb-16 md:pb-24 space-y-14 max-w-6xl mx-auto">
      <Strategy
        img={"/assets/seed.svg"}
        alt={"seed design"}
        type={strategy.type}
        title={strategy.title}
        subHeading={strategy.subHeading}
        firstPara={strategy.firstPara}
        secondPara={strategy.secondPara}
        features={strategy.features}
      />
      <Strategy
        class={"border-t-4 border-b-4 pt-10 pb-10"}
        img={"/assets/sow.svg"}
        alt={"sow design"}
        type={brand.type}
        title={brand.title}
        subHeading={brand.subHeading}
        firstPara={brand.firstPara}
        secondPara={brand.secondPara}
        features={brand.features}
      />
      <Strategy
        img={"/assets/harvest.svg"}
        alt={"harvest design"}
        type={sales.type}
        title={sales.title}
        subHeading={sales.subHeading}
        firstPara={sales.firstPara}
        secondPara={sales.secondPara}
        features={sales.features}
      />
    </section>
  );
};

export default StrategyBlock;
