import React from "react";
import I18nContext from "../lib/i18n-context";
import WorkCardsBlock from "../components/WorkCardsBlock";

const OurServicesInAction = () => {
  const { locale } = React.useContext(I18nContext);

  const data = locale

  return (
    <section className="h-auto w-full text-darkblue">
      <div className="container max-w-screen-xl flex flex-col justify-center text-left px-10 py-16 space-y-8 lg:px-20">
        <div className="text-center">
          <div className="font-arima text-3xl sm:text-4xl md:text-3xl lg:text-4xl font-light">
            {data.ourServicesInAction.title}
          </div>
        </div>
        {/* CARDS */}
        <WorkCardsBlock data={data.ourWork} border={true} />
      </div>
    </section>
  );
};

export default OurServicesInAction;
