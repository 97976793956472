import React from 'react';

const StrategyBlock = props => (
  <div
    className={`px-10 sm:flex items-center sm:space-x-10 ${props.class}`}
  >
    <div className="w-full lg:w-1/4">
      <img
        data-aos="fade-down"
        data-aos-duration="1900"
        className=" w-full"
        src={props.img}
        alt={props.alt}
      />
    </div>
    <div className="sm:col-span-3 md:col-span-2 lg:w-2/4">
      <div className="space-y-2">
        <h5 className="font-poppins text-xs text-lightblue">{props.type}</h5>
        <h2 className="font-arima text-darkblue text-3xl">{props.title}</h2>
        <h3 className="pt-1 pb-2 text-lightblue font-arima font-semibold">
          {props.subHeading}
        </h3>
        <p className="text-xs text-lightblue font-poppins lg:text-sm">
          {props.firstPara}
          <br />
          <br />
          {props.secondPara}
        </p>
      </div>
    </div>
    <div className="hidden lg:block pt-12 lg:pt-20 lg:w-1/4 self-start">
      <ul className="space-y-1.5 text-darkblue font-poppins text-sm font-medium">
        {props.features.map((feature) => (
          <li key={feature.id}>{feature.title}</li>
        ))}
      </ul>
    </div>
  </div>
);

export default StrategyBlock;
